// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}
.react-autosuggest__suggestions-list{
  margin-top: 70%;
  background-color: green;
}
.react-autosuggest__input{
  z-index: 100;
}
.react-autosuggest__container{
}
.react-autosuggest__container--open{
background-color: floralwhite;
z-index: 99
;
}
.react-autosuggest__suggestions-container{
}
.react-autosuggest__suggestion{
  background-color: white;
  margin-top: 4%;

}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,uBAAuB;AACzB;AACA;EACE,YAAY;AACd;AACA;AACA;AACA;AACA,6BAA6B;AAC7B;AACA;AACA;AACA;AACA;AACA;EACE,uBAAuB;EACvB,cAAc;;AAEhB","sourcesContent":[".App {\n  text-align: center;\n}\n.react-autosuggest__suggestions-list{\n  margin-top: 70%;\n  background-color: green;\n}\n.react-autosuggest__input{\n  z-index: 100;\n}\n.react-autosuggest__container{\n}\n.react-autosuggest__container--open{\nbackground-color: floralwhite;\nz-index: 99\n;\n}\n.react-autosuggest__suggestions-container{\n}\n.react-autosuggest__suggestion{\n  background-color: white;\n  margin-top: 4%;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
